import React from 'react';

const PitchSection = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="text-center max-w-xl mx-auto">
        <h2 className="text-3xl font-semibold mb-4">Pitch Your Vision, Let's Talk.</h2>
        <p className="text-gray-600 mb-6">
          Got an innovative venture? Share your pitch with NorthstarCapital and schedule a meeting.
          Submit your email below, and let's explore the potential partnership together.
        </p>
        <div className="flex flex-col items-center">
          <input
            type="email"
            placeholder="Enter your email"
            className="p-2 border border-gray-300 rounded-md w-full max-w-xs mb-4"
          />
          <button className="bg-yellow-400 text-white px-6 py-2 w-full max-w-xs rounded-md transition duration-300 hover:bg-yellow-300">
            Submit
          </button>
        </div>
      </div>

      <div className="border-t border-gray-300 mt-12 pt-8">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
          {/* Company Links */}
          <div>
            <h3 className="font-semibold">NorthstarCapital</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="#" className="text-gray-600 hover:underline">Company</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Solution</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Portfolio</a></li>
            </ul>
          </div>

          {/* Resource Links */}
          <div>
            <h3 className="font-semibold">Resource</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="#" className="text-gray-600 hover:underline">Blog</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Article</a></li>
            </ul>
          </div>

          {/* Template Links */}
          <div>
            <h3 className="font-semibold">Template</h3>
            <ul className="mt-4 space-y-2">
              <li><a href="#" className="text-gray-600 hover:underline">Changelog</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">License</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Styleguide</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Sign In</a></li>
              <li><a href="#" className="text-gray-600 hover:underline">Sign Up</a></li>
            </ul>
          </div>

          {/* Newsletter Subscription */}
          <div>
            <h3 className="font-semibold">Join our newsletter</h3>
            <p className="text-gray-600 mt-4">
              Join our newsletter to stay up to date on features and releases.
            </p>
            <div className="flex mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 border border-gray-300 rounded-l-md w-full"
              />
              <button className="bg-green-800 text-white px-4 rounded-r-md">
                Submit
              </button>
            </div>
            <p className="text-xs text-gray-500 mt-2">
              By subscribing, you agree to our Privacy Policy and consent to receive updates from our company.
            </p>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-8 text-center text-gray-500 text-sm">
          <p>© NorthstarCapital. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default PitchSection;
  