// import React from 'react';

// const Company = () => {
//   return (
//     <div className="container  mx-auto  font-roboto">
//       <div className='wrapper m-auto w-10/12'>
//       {/* Header Section */}
//       <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-6">
//         {/* Heading */}
//         <div className="text-left md:w-1/2">
//           <h1 className="text-4xl font-bold-jakarta leading-tight">
//             Venture Boldly Way,<br />Thrive Together.
//           </h1>
//         </div>

//         {/* Info Section */}
//         <div className="flex flex-col  md:flex-row justify-end md:w-1/2 mt-4 md:mt-0">
//           <div className="text-left md:text-left mx-4">
//             <p className="text-xs font-plex font-bold pb-2">FOUNDED IN</p>
//             <p className="text-lg font-inter">2007</p>
//           </div>
//           <div className="text-left md:text-left mx-4">
//             <p className="text-xs font-plex font-bold pb-2">PORTFOLIO</p>
//             <p className="text-lg font-inter">100+ Companies</p>
//           </div>
//           <div className="text-left md:text-left mx-4">
//             <p className="text-xs font-plex font-bold pb-2">HEADQUARTER</p>
//             <p className="text-lg font-inter">Stavanger (Headquarters)</p>
//           </div>
//         </div>
//       </div>

//       {/* Image Section */}
//       <div className="mt-8 flex justify-center">
//         <img
//           className="rounded-lg"
//           src={`${process.env.PUBLIC_URL}/img/tth.png`}
//           alt="A group of people in a modern office setting, one person standing and presenting in front of a screen, others sitting and listening attentively."
//         />
//       </div>
//       <div className="flex justify-center items-center h-[70vh] bg-white">
//       <div className="max-w-4xl mx-auto px-4 flex">
//         {/* Heading Section */}
//         <div className="w-1/3">
//           <h1 className="text-4xl font-bold font-jakarta">Our Story</h1>
//         </div>

//         {/* Content Section */}
//         <div className="w-2/3 text-lg text-gray-700 space-y-6">
//           <p>
//             In the ever-evolving landscape of innovation, NorthstarCapital emerged as a guiding force, a catalyst for dreams seeking to reshape industries and redefine possibilities. Our story is one of vision, partnership, and a relentless commitment to fostering the spirit of entrepreneurship.
//           </p>
//           <p>
//             NorthstarCapital was founded on the belief that great ideas deserve not just financial backing but strategic partnerships that propel them towards success. In the corridors of our inception, the dream was to create a venture capital firm that wasn't just an investor but a collaborative ally in the journey of ambitious entrepreneurs.
//           </p>
//         </div>
//       </div>
//     </div>
    

//     <div className="container  mx-auto p-4">
//       <div className="flex justify-between">
//         {/* First Div: Contains Image 1 and Image 3 */}
//         <div className="flex flex-col w-1/2 gap-4 p-2">
//           {/* First Image */}
//           <img
//             className="rounded-lg object-cover w-full h-[121]"
//             src={`${process.env.PUBLIC_URL}/img/IMG_1`}
//             alt="Image 1"
//           />
//           {/* Third Image */}
//           <img
//             className="rounded-lg object-cover w-full h-[90]"
//             src={`${process.env.PUBLIC_URL}/img/IMG_3`}
//             alt="Image 3"
//           />
//         </div>

//         {/* Second Div: Contains Image 2 and Image 4 */}
//         <div className="flex flex-col w-1/2 gap-4 p-2">
//           {/* Second Image with flexible height */}
//           <img
//             className="rounded-lg object-cover w-full h-[80]"
//             src={`${process.env.PUBLIC_URL}/img/IMG_2`}
//             alt="Image 2" 
//           />
//           {/* Fourth Image with flexible height */}
//           <img
//             className="rounded-lg object-cover w-full  h-[110]"
//             src={`${process.env.PUBLIC_URL}/img/IMG_4`}
//             alt="Image 4"
//           />
//         </div>
//       </div>

//       <div className="flex justify-center items-center h-[70vh] bg-white">
//       <div className="max-w-4xl mx-auto px-4 flex">
//         {/* Heading Section */}
//         <div className="w-1/3">
//           <h1 className="text-4xl font-bold font-jakarta">Our mission</h1>
//         </div>

//         {/* Content Section */}
//         <div className="w-2/3 text-lg text-gray-700 space-y-6">
//           <p>
//           At NorthstarCapital, our mission is to empower visionary entrepreneurs and innovative ventures to thrive. We believe in fostering a dynamic ecosystem where groundbreaking ideas are not just nurtured but propelled towards success.
//           </p>
//           <p>
//           Through strategic investments, mentorship, and a commitment to sustainable practices, we aim to be the catalyst for transformative ventures that shape the future.
//           </p>
//         </div>
//       </div>
//     </div>
//     </div>


//     <div className="max-w-4xl mx-auto p-8 bg-white">
//       <div className="text-left">
//         <p className="text-sm font-bold text-gray-600">CEO INSIGHTS</p>
//         <h1 className="text-4xl font-bold text-black mt-2">
//           NorthstarCapital CEO's vision for innovation and entrepreneurial impact
//         </h1>
//       </div>
//       <div className="mt-8">
//         <div className="relative">
//           <video
//             className="w-full h-auto rounded-lg"
//             controls
//             poster="https://placehold.co/800x450" // Optional poster image
//           >
//            <source src={`${process.env.PUBLIC_URL}/img/Vdo.mp4`} type="video/mp4" />
//                      Your browser does not support the video tag.
//             </video>
//           <button className="absolute inset-0 flex items-center justify-center">
//             <i className="fas fa-play-circle text-white text-6xl"></i>
//           </button>
//         </div>
//       </div>
//     </div>

   
//     </div>
//     <div className="bg-[#0e382b] text-white min-h-screen font-roboto">
//       <div className="p-10 w-10/12 mx-auto">
//         {/* Heading section */}
//         <div className="text-left">
//           <p className="pt-10 font-plex text-sm tracking-widest">CEO INSIGHTS</p>
//           <h1 className="text-5xl pt-5 font-jakarta  mt-2">A Journey of Innovation <br/>and Success</h1>
//         </div>

//         {/* Stats section */}
//         <div className="mt-10 border-t border-gray-600 pt-10">
//           <div className="flex flex-col md:flex-row justify-between">
//             <div className="text-left pb-10 mb-6 md:mb-0">
//               <p className="text-[4rem] font-bold text-[#cefe85]">$8B+</p>
//               <p className="mt-2">Under Management</p>
//             </div>
//             <div className="text-left mb-6 md:mb-0">
//               <p className="text-[4rem] font-bold text-[#cefe85]">100+</p>
//               <p className="mt-2">Active Portfolio Companies</p>
//             </div>
//             <div className="text-left mb-6 md:mb-0">
//               <p className="text-[4rem] font-bold text-[#cefe85]">65</p>
//               <p className="mt-2">IPO's Portfolio</p>
//             </div>
//             <div className="text-left">
//               <p className="text-[4rem] font-bold text-[#cefe85]">175</p>
//               <p className="mt-2">MMA</p>
//             </div>
//           </div>
//         </div>

//         {/* Paragraph section */}
//         <div className="mt-10 text-[14px] text-left md:text-left md:flex md:justify-end">
//           <p className="max-w-[30rem] text-xl ">
//             As we navigate the future, our commitment remains unwavering. We continue to seek out visionary entrepreneurs, 
//             disruptive technologies, and opportunities that align with our mission. Our story is a perpetual journey, 
//             and every venture we support is a new chapter in the evolving narrative of NorthstarCapital.
//           </p>
//         </div>
//       </div>
//     </div>
//     <div>
//     <div className="min-h-screen w-10/12 m-auto bg-white text-gray-800 px-8 py-12">
//       {/* Company Values Heading */}
//       <div className="text-left mb-10">
//         <p className="text-xs tracking-widest font-plex text-gray-500">COMPANY VALUES</p>
//         <h1 className="text-5xl font-jakarta max-w-xl mt-2">Unveiling the Core Values at NorthstarCapital</h1>
//       </div>

//       {/* Core Values Cards */}
//       <div className=" grid grid-cols-1 h-[400px] md:grid-cols-3 gap-8">
//         {/* Card 1: Innovation Prowess */}
//         <div className="bg-[#0e382b] flex flex-col justify-between h-full text-[#cefe85] p-8 rounded-lg">
//   <h2 className="text-xs font-plex ">INNOVATION PROWESS</h2>
//   <p className="text-[#cefe85] text-lg mt-auto">
//     We celebrate and encourage a culture of relentless innovation. We believe in the power of daring ideas and disruptive thinking.
//   </p>
// </div>


//         {/* Card 2: Collaborative Synergy */}
//         <div className="bg-green-50 flex flex-col justify-between h-full text-green-900 p-8 rounded-lg">
//   <h2 className="text-xs font-plex ">COLLABORATIVE SYNERGY</h2>
//   <p className="text-lg mt-auto">
//     Collaboration is the cornerstone of our success. We foster a collaborative environment that brings together.
//   </p>
// </div>


//         {/* Card 3: Impactful Sustainability */}
//         <div className="bg-green-100 flex flex-col justify-between h-full text-green-900 p-8 rounded-lg">
//   <h2 className="text-xs font-plex ">IMPACTFUL SUSTAINABILITY</h2>
//   <p className="text-lg mt-auto">
//     We celebrate and encourage a culture of relentless innovation. We believe in the power of daring ideas and disruptive.
//   </p>
// </div>

//       </div>
//     </div>
//     </div>
//     </div>
    
//   );
// };

// export default Company;


import React from 'react';

const Company = () => {
  return (
    <div className="container mx-auto font-roboto">
      <div className='wrapper m-auto w-full md:w-10/12'>
        {/* Header Section */}
        <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-6">
          {/* Heading */}
          <div className="text-left md:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold-jakarta leading-tight">
              Venture Boldly Way,<br />Thrive Together.
            </h1>
          </div>

          {/* Info Section */}
          <div className="flex flex-col md:flex-row justify-end md:w-1/2 mt-4 md:mt-0">
            <div className="text-left mx-2 sm:mx-4">
              <p className="text-xs font-plex font-bold pb-2">FOUNDED IN</p>
              <p className="text-base sm:text-lg font-inter">2007</p>
            </div>
            <div className="text-left mx-2 sm:mx-4">
              <p className="text-xs font-plex font-bold pb-2">PORTFOLIO</p>
              <p className="text-base sm:text-lg font-inter">100+ Companies</p>
            </div>
            <div className="text-left mx-2 sm:mx-4">
              <p className="text-xs font-plex font-bold pb-2">HEADQUARTER</p>
              <p className="text-base sm:text-lg font-inter">Stavanger (Headquarters)</p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className="mt-8 flex justify-center">
          <img
            className="rounded-lg w-full sm:w-auto"
            src={`${process.env.PUBLIC_URL}/img/tth.png`}
            alt="A group of people in a modern office setting"
          />
        </div>

        <div className="flex justify-center items-center h-[50vh] sm:h-[70vh] bg-white">
          <div className="max-w-4xl mx-auto px-4 flex flex-col md:flex-row">
            {/* Heading Section */}
            <div className="w-full md:w-1/3 mb-4 md:mb-0">
              <h1 className="text-3xl sm:text-4xl font-bold font-jakarta">Our Story</h1>
            </div>

            {/* Content Section */}
            <div className="w-full md:w-2/3 text-base sm:text-lg text-gray-700 space-y-6">
              <p>
                In the ever-evolving landscape of innovation, NorthstarCapital emerged as a guiding force...
              </p>
              <p>
                NorthstarCapital was founded on the belief that great ideas deserve not just financial backing but...
              </p>
            </div>
          </div>
        </div>

        {/* Images Section */}
        <div className="container mx-auto p-4">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col md:w-1/2 gap-4 p-2">
              <img className="rounded-lg object-cover w-full h-[121px]" src={`${process.env.PUBLIC_URL}/img/IMG_1`} alt="Image 1" />
              <img className="rounded-lg object-cover w-full h-[90px]" src={`${process.env.PUBLIC_URL}/img/IMG_3`} alt="Image 3" />
            </div>

            <div className="flex flex-col md:w-1/2 gap-4 p-2">
              <img className="rounded-lg object-cover w-full h-[80px]" src={`${process.env.PUBLIC_URL}/img/IMG_2`} alt="Image 2" />
              <img className="rounded-lg object-cover w-full h-[110px]" src={`${process.env.PUBLIC_URL}/img/IMG_4`} alt="Image 4" />
            </div>
          </div>

          <div className="flex justify-center items-center h-[50vh] sm:h-[70vh] bg-white">
            <div className="max-w-4xl mx-auto px-4 flex flex-col md:flex-row">
              <div className="w-full md:w-1/3">
                <h1 className="text-3xl sm:text-4xl font-bold font-jakarta">Our Mission</h1>
              </div>
              <div className="w-full md:w-2/3 text-base sm:text-lg text-gray-700 space-y-6">
                <p>At NorthstarCapital, our mission is to empower visionary entrepreneurs and innovative ventures...</p>
              </div>
            </div>
          </div>
        </div>

        {/* CEO Insights */}
        <div className="max-w-4xl mx-auto p-8 bg-white">
          <div className="text-left">
            <p className="text-sm font-bold text-gray-600">CEO INSIGHTS</p>
            <h1 className="text-3xl sm:text-4xl font-bold text-black mt-2">
              NorthstarCapital CEO's vision for innovation and entrepreneurial impact
            </h1>
          </div>
          <div className="mt-8">
            <div className="relative">
              <video className="w-full h-auto rounded-lg" controls poster="https://placehold.co/800x450">
                <source src={`${process.env.PUBLIC_URL}/img/Vdo.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <button className="absolute inset-0 flex items-center justify-center">
                <i className="fas fa-play-circle text-white text-6xl"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Footer Section */}
        <div className="bg-[#0e382b] text-white min-h-screen font-roboto">
          <div className="p-10 w-full sm:w-10/12 mx-auto">
            <div className="text-left">
              <p className="pt-10 font-plex text-sm tracking-widest">CEO INSIGHTS</p>
              <h1 className="text-3xl sm:text-4xl md:text-5xl pt-5 font-jakarta mt-2">A Journey of Innovation <br/>and Success</h1>
            </div>

            <div className="mt-10 border-t border-gray-600 pt-10">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="text-left pb-10">
                  <p className="text-5xl font-bold text-[#cefe85]">$8B+</p>
                  <p className="mt-2">Under Management</p>
                </div>
                <div className="text-left">
                  <p className="text-5xl font-bold text-[#cefe85]">100+</p>
                  <p className="mt-2">Active Portfolio Companies</p>
                </div>
                <div className="text-left">
                  <p className="text-5xl font-bold text-[#cefe85]">65</p>
                  <p className="mt-2">IPO's Portfolio</p>
                </div>
                <div className="text-left">
                  <p className="text-5xl font-bold text-[#cefe85]">175</p>
                  <p className="mt-2">MMA</p>
                </div>
              </div>
            </div>

            <div className="mt-10 text-left md:flex md:justify-end">
              <p className="max-w-xl text-xl">
                As we navigate the future, our commitment remains unwavering...
              </p>
            </div>
          </div>
        </div>

        {/* Company Values */}
        <div className="min-h-screen w-full sm:w-10/12 m-auto bg-white text-gray-800 px-8 py-12">
          <div className="text-left mb-10">
            <p className="text-xs tracking-widest font-plex text-gray-500">COMPANY VALUES</p>
            <h1 className="text-3xl sm:text-5xl font-jakarta max-w-xl mt-2">Unveiling the Core Values at NorthstarCapital</h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-[#0e382b] flex flex-col justify-between text-[#cefe85] p-8 rounded-lg">
              <h2 className="text-xs font-plex">INNOVATION PROWESS</h2>
              <p className="text-lg mt-auto">We celebrate and encourage a culture of relentless innovation...</p>
            </div>

            <div className="bg-green-50 flex flex-col justify-between text-green-900 p-8 rounded-lg">
              <h2 className="text-xs font-plex">COLLABORATIVE SYNERGY</h2>
              <p className="text-lg mt-auto">Our strength lies in fostering meaningful partnerships...</p>
            </div>

            <div className="bg-[#0e382b] flex flex-col justify-between text-[#cefe85] p-8 rounded-lg">
              <h2 className="text-xs font-plex">GLOBAL IMPACT</h2>
              <p className="text-lg mt-auto">NorthstarCapital is committed to making a positive difference...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
