// // import React from "react";
// // import Header from "./components/Header";
// // import Home from "./components/Home";
// // import GreenCard from "./components/GreenCard";
// // import Portfolio from "./components/Portfolio";
// // import Footer from "./components/Footer";
// // import TeamMembers from "./components/TeamMembers"
// // // import Company from "./Pages/Company";
// //     const App = () => {
// //     return (
// //       <div>
// //         <Header/>
// //         <Home/>
// //         <GreenCard/>
// //         <Portfolio/>
// //         <TeamMembers/>
// //         <Footer/>
// //       </div>
// //     );
// //     };

// //     export default App;


// // import React from "react";
// // import Header from "./components/Header";
// // import Home from "./components/Home";
// // import GreenCard from "./components/GreenCard";
// // import Portfolio from "./components/Portfolio";
// // import Footer from "./components/Footer";
// // import TeamMembers from "./components/TeamMembers";
// // // import Company from "./components/Company";
// // import {
// //   Route,
// //   Switch,
// //   Routes,
// //   BrowserRouter,
// // } from "react-router-dom";

// // const App = () => {
// //   return (
// //     <div>
// //       <Header />
// //       <Home />
// //       <GreenCard />
// //       <Portfolio />
// //       <TeamMembers />
// //       <Footer />

      
// //     </div>
// //   );
// // };

// // export default App;


// import React from "react";
// import Header from "./components/Header";
// import Home from "./components/Home";
// import GreenCard from "./components/GreenCard";
// import Portfolio from "./components/Portfolio";
// import Footer from "./components/Footer";
// import TeamMembers from "./components/TeamMembers";
// // import Company from "./components/Company"; // Uncomment if you need Company component
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// const App = () => {
//   return (
//     <Router> {/* Wrapping the app in BrowserRouter */}
//       <div>
//         <Header />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/green-card" element={<GreenCard />} />
//           <Route path="/portfolio" element={<Portfolio />} />
//           <Route path="/team-members" element={<TeamMembers />} />
//           {/* <Route path="/company" element={<Company />} /> */}
//         </Routes>
//         <Footer />
//       </div>
//     </Router>
//   );
// };

// export default App;


import React from "react";
import Header from "./components/Header";
import Home from "./components/Home/Home"; // Updated to the new Home component that combines all others
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Company from "./components/Company";
import Portfolio from "./components/Portfolio";

const App = () => {
  return (
    
    <Router> {/* Wrapping the app in BrowserRouter */}
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home component now includes everything */}
          <Route path="/company" element={<Company />} /> {/* Home component now includes everything */}
          <Route path="/Portfolio" element={<Portfolio />} />
        </Routes>
        <Footer />
      </div>
    </Router>
    
  );
};

export default App;
