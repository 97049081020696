import React from "react";

const GreenCard = () => {
  return (
    <div className="bg-[#0B3D2E] text-white flex justify-center items-center py-36 ">
      <div className="lg:w-10/12 lg:px-4 m-4 px-0 lg:m-auto">
        <h1 className="text-4xl font-bold mb-8">The numbers</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <div className="mb-10">
              <div className="border-b border-gray-600 mb-4"></div>
              <p className="text-lg mb-4">Years on the market</p>
              <p className="text-5xl text-[#cefe85] ">5+</p>
            </div>
            <div>
              <div className="border-b border-gray-600 mb-4"></div>
              <p className="text-lg mb-4">Capital we've raised</p>
              <p className="text-5xl text-[#cefe85] ">€35M+</p>
            </div>
          </div>
          <div>
            <div className="mb-10">
              <div className="border-b border-gray-600 mb-4"></div>
              <p className="text-lg mb-4">Ventures we've founded</p>
              <p className="text-5xl text-[#cefe85] ">15+</p>
            </div>
            <div>
              <div className="border-b border-gray-600 mb-4"></div>
              <p className="text-lg mb-4">Shareholder value created</p>
              <p className="text-5xl text-[#cefe85]">€150M+</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreenCard;
