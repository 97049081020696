import React from "react";

// Array of team members with image URLs from public/img
const teamMembers = [
  {
    name: "Barry Allen",
    title: "Senior Portfolio Manager",
    imageUrl:
      "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65bb48a4f2009d5d4972be15_Member%20Photo%20Profil08.png", // Adjust the filename as per your image
  },
  {
    name: "Oliver Queen",
    title: "Chief Financial Officer",
    imageUrl:
      "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65bb48877afdae0724d1e95b_Member%20Photo%20Profil07.png", // Adjust the filename as per your image
  },
  {
    name: "Clark Kent",
    title: "Sustainability Impact Manager",
    imageUrl:
      "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65bb4867f286aa016eccfd49_Member%20Photo%20Profil06.png", // Adjust the filename as per your image
  },
  {
    name: "Henry McGuire",
    title: "Managing Partner",
    imageUrl:
      "https://cdn.prod.website-files.com/65b89349074833e8d1556246/65bb47aa2bcee7b5efc37ab6_Member%20Photo%20Profil01.png",
  },
];

const TeamSection = () => {
  return (
    <section className="bg-green-50 py-12 px-6 lg:w-10/12 m-4 lg:m-auto">
      <div className="container mx-auto text-center">
        <h2 className="text-sm uppercase tracking-wide text-gray-500 mb-4">
          Meet the visionaries behind NorthstarCapital
        </h2>
        <h1 className="text-4xl font-bold text-gray-900 mb-10">
          Fueling ventures with best expertise in the field
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {teamMembers.map((member) => (
            <div
              key={member.name}
              className="relative bg-white rounded-lg shadow-md overflow-hidden transition-all transform hover:scale-105 hover:z-10"
              style={{ zIndex: 1 }}
            >
              <img
                src={member.imageUrl}
                alt={member.name}
                className="w-full h-56 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  {member.name}
                </h3>
                <p className="text-sm text-gray-500">{member.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
