import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Handle clicks outside the menu to close it
  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".menu-button") &&
      !event.target.closest(".menu")
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle scrolling to add a shadow effect when scrolled
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`w-full flex justify-center items-center p-4 transition-all duration-300 ${
        scrolled ? "backdrop-blur-md bg-opacity-70" : ""
      } sticky top-0 z-50 bg-white`}
    >
      <div className="w-full sm:w-10/12 flex justify-between sm:px-0">
        {/* Left section: Logo and Nav */}
        <div className="flex items-center space-x-8">
          <Link to="/" className="text-2xl">
            <span className="text-black font-bold">Northstar</span>
            <span className="text-gray-700">Capital</span>
          </Link>

          {/* Desktop Nav, shown only when menu is closed */}
          {!isOpen && (
            <nav className="hidden md:flex space-x-10 ml-4">
              <Link
                to="/All Pages"
                className="text-gray-900 hover:text-gray-400 text-[0.900rem]"
              >
                All Pages
              </Link>
              <Link
                to="/company"
                className="text-gray-900 hover:text-gray-400 text-[0.900rem]"
              >
                Company
              </Link>
              <Link
                to="/solution"
                className="text-gray-900 hover:text-gray-400 text-[0.900rem]"
              >
                Solution
              </Link>
              <Link
                to="/portfolio"
                className="text-gray-900 hover:text-gray-400 text-[0.900rem]"
              >
                Portfolio
              </Link>
              <Link
                to="/blog"
                className="text-gray-900 hover:text-gray-400 text-[0.900rem]"
              >
                Blog
              </Link>
            </nav>
          )}
        </div>

        {/* Right section: Contact Button and Mobile Menu Button */}
        <div className="flex items-center space-x-4">
          <button className="hidden md:block bg-yellow-300 text-black px-4 py-2 rounded">
            Contact Us
          </button>
          <div className="md:hidden menu-button ml-auto">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 focus:outline-none"
            >
              <i className="fas fa-bars text-3xl"></i>
            </button>
          </div>
        </div>
      </div>

      {/* Fullscreen Mobile Menu with Slide Down/Slide Up Effect */}
      <div
        className={`${
          isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        } overflow-hidden transition-all duration-500 ease-in-out bg-white flex flex-col justify-center items-center space-y-6 text-3xl w-full absolute top-[64px] z-40`}
        style={{
          height: isOpen ? "100vh" : "0", // Ensures full screen height
          transition: "height 0.5s ease-in-out, opacity 0.5s ease-in-out", // Smooth transition for both height and opacity
        }}
      >
        {isOpen && (
          <nav className="flex flex-col items-center space-y-8">
            <Link
              to="/company"
              className="text-gray-900 hover:text-gray-400"
              onClick={() => setIsOpen(false)}
            >
              Company
            </Link>
            <Link
              to="/solution"
              className="text-gray-900 hover:text-gray-400"
              onClick={() => setIsOpen(false)}
            >
              Solution
            </Link>
            <Link
              to="/portfolio"
              className="text-gray-900 hover:text-gray-400"
              onClick={() => setIsOpen(false)}
            >
              Portfolio
            </Link>
            <Link
              to="/blog"
              className="text-gray-900 hover:text-gray-400"
              onClick={() => setIsOpen(false)}
            >
              Blog
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
